/**
 * Nosy Post
 *
 * @export Post
 */
import { Box, Container, Heading, Stack, Text, Tooltip, useBreakpointValue } from '@chakra-ui/react'
import { PortableText } from '@portabletext/react'
import { graphql } from 'gatsby'
import React from 'react'
import BackLink from '../../components/back-link/back-link'
import CallToAction from '../../components/call-to-action/call-to-action'
import Image from '../../components/image/image'
import Layout from '../../components/layout/layout'
import Link from '../../components/link/link'
import { BlogRow } from '../../components/sections'
import SEO from '../../components/seo/seo'
import { leadSerializer, postSerializer } from '../../serializers'
import { mapEdgesToNodes } from '../../utils/helpers'

// Query variables passed in from pageContext
export const query = graphql`
    query TEMPLATE_POST_QUERY($id: String!) {
        sanityPost(id: { eq: $id }) {
            title
            seo: _rawSeo(resolveReferences: { maxDepth: 10 })
            author: _rawTeams(resolveReferences: { maxDepth: 10 })
            publishedAt(formatString: "DD MMMM, YYYY")
            excerpt: _rawExcerpt(resolveReferences: { maxDepth: 10 })
            body: _rawBody(resolveReferences: { maxDepth: 10 })
            mainImage {
                ...ImageWithPreview
            }
            slug {
                current
            }
        }
        allSanityPost(
            filter: { id: { ne: $id }, _id: { regex: "/^(?!drafts).*/" } }
            sort: { fields: publishedAt, order: DESC }
            limit: 3
        ) {
            edges {
                node {
                    id
                    title
                    excerpt: _rawExcerpt
                    mainImage {
                        ...ImageWithPreview
                    }
                    slug {
                        current
                    }
                }
            }
        }
    }
`

const Post = ( props ) => {
    // console.log( '🦄 ~ file: post.js ~ line 34 ~ Post ~ props', { props } )
    const {
        data: {
            sanityPost: {
                title = '',
                seo: { 
                    seoTitle = '', 
                    seoDescription = ''
                },
                author = [],
                excerpt = [],
                publishedAt = '',
                mainImage = {},
                slug: { current: url = '' },
                body = []
            },
            allSanityPost = []
        }
    } = props
    
    // Destructure team (author) reference
    const [
        {
            team: { name: authorName = '', mainImage: authorImage, slug = {} }
        }
    ] = author

    // Map edges to node item
    const posts = mapEdgesToNodes( allSanityPost )

    const imageWidths = useBreakpointValue( {
        base: 447, 
        sm: 735,
        md: 896
    } )

    return (
        <Layout>
            <SEO
                title={`${seoTitle} | Blog`}
                description={seoDescription}
                image={mainImage}
                url={`/blog/${url}/`}
                type='article' />
            <Container maxWidth='6xl'>
                <Tooltip
                    label='See all posts'
                    aria-label='See all posts'
                    fontSize='md'
                    placement='top'
                    backgroundColor='brand_primary.700'
                    color='white'
                    arrowShadowColor='brand_primary.700'>
                    <Box as='span' width='32px' height='32px' display='block'>
                        <BackLink to='/blog/' />
                    </Box>
                </Tooltip>
                <Stack spacing={6} my={6} maxWidth='2xl' mx='auto'>
                    <Heading 
                        as='h1' 
                        fontSize='5xl' 
                        color='gray.100'
                        fontFamily='Modelica-Bold'>
                        {title}
                    </Heading>
                    <PortableText
                        value={excerpt}
                        components={leadSerializer} />
                    <Stack
                        direction='row'
                        alignItems='center'
                        justifyContent='flex-start'>
                        <Image
                            imageData={authorImage}
                            width={26}
                            height={26}
                            rounded='full' />
                        <Text fontSize='xl' color='gray.400'>
                            <Link to={`/team/${slug.current}/`}>
                                {authorName}
                            </Link>
                            , {publishedAt}
                        </Text>
                    </Stack>
                </Stack>
                <Image
                    imageData={mainImage}
                    loading='eager'
                    width={imageWidths}
                    height={500}
                    mb={6}
                    maxWidth='4xl'
                    mx='auto'
                    sx={{
                        width: 'full'
                    }} />
            </Container>

            <Container maxWidth='2xl' mt={10} mb={[20, 40]}>
                <PortableText 
                    value={body} 
                    components={postSerializer} />
            </Container>

            <Container maxWidth='6xl' mb={[20, 40]}>
                <BlogRow heading='More posts' posts={posts} />
            </Container>

            <Container maxWidth='6xl' mb={[20, 40]}>
                <CallToAction />
            </Container>
        </Layout>
    )
}

export default Post
